.dgf-list{

    + .dgf-list{
        margin-top: 48px;
    }

    &__header,
    &-combined__header{
        margin-bottom: 32px;

        @include mq(md) {
            margin-bottom: 48px;
        }

        .accordion-body & {
            margin-bottom: 24px;

            @include mq(md) {
                margin-bottom: 32px;
            }
        }

        hr{
            border-top-color: $color__gray;
            opacity: 1;
        }
    }

    &__body{
        hr{
            margin: 0 0 24px;

            border-top-color: $color__font;
            opacity: 1;
        }

        > .splide {
            > .splide__track {
                margin-top: -32px;
                padding-top: 32px;
            }
        }
    }

    &__section{
        &:not(:last-of-type){
            margin-bottom: 48px;
        }
    }

    &__button,
    &-combined__button{

        &:not([aria-pressed="true"]){
            border: 1px solid $color__gray;
            background-color: $color__white;
            img{
                filter: var(--filter-primary-hover);
            }

            &:hover,
            &:focus-within{
                background-color: $color__primary--hover;
                border-color: $color__primary--hover;
                img{
                    filter: brightness(0) invert(1);
                }
            }
        }

        &[aria-pressed="true"]{
            border: 1px solid $color__primary--hover;
            background-color: $color__primary--hover;
            img{
                filter: brightness(0) invert(1);
            }
        }


    }

    .card{
        --bs-card-spacer-y: 1rem;
        --bs-card-spacer-x: 1rem;
        --bs-card-cap-padding-x: 1rem;

        @include mq(md) {
            --bs-card-spacer-y: 1.5rem;
            --bs-card-spacer-x: 1.5rem;
            --bs-card-cap-padding-x: 1.5rem;
        }

        a {
            color: $color__link!important;

            &.standalone {
                color: $color__font!important;

                &:hover,
                &:focus {
                    text-decoration-color: currentColor!important;
                }
            }
        }

        .card--info-popup{
            a{
                color: #fff !important;
            }
        }

        &-header{
            border: none;
            font-size: 1rem;
        }

        &--has-image {
            .card-header {
                margin-top: -4px;
                border-radius: 4px 4px 0 0;

                @include mq(sm,max){
                    margin-top: 0;
                }
            }
        }

        &-icon{
            display: inline-block;
            background-color: var(--color-gray-light);
            border: 4px solid #ffffff;

            &:not(:first-of-type){
                margin-left: -8px;
            }

            &--rounded{
                border-radius: 50%;
            }

            &:hover {
                &:before {
                    left: 0;
                    transform: translateX(0);
                }
            }
        }

        .dgf-card-image,
        .card-img-top{
            @include mq(sm,max){
                display: none;
            }

            &--logo {
                padding: 16px 24px; //3:2
                aspect-ratio: 3/2;
                border-bottom: 1px solid $color__gray;

                @include mq(md) {
                    padding: 32px 48px; //3:2
                }
            }
        }

        &--highlight {
            position: relative;
            background-color: $color__gray--light;

            .icon--highlight {
                position: absolute;
                right: 16px;
                top: 16px;
                padding: 4px;

                background-color: $color__white;
                border: 1px solid var(--bs-border-color);
                border-radius: var(--bs-border-radius);

                > img {
                    filter: var(--filter-gray);
                }

                &:hover {
                    &:before {
                        left: auto;
                        right: 0;
                        transform: translateX(0);

                        .splide--slide & {
                            bottom: auto;
                            top: calc(100% + 0.5rem);
                        }
                    }

                    &:after {
                        .splide--slide & {
                            border-bottom-color: currentColor;
                            border-top-color: transparent;
                            border-top-width: 0;
                            border-bottom-width: .5rem;
                            bottom: auto;
                            top: 100%;
                        }
                    }
                }
            }

            .card-icon {
                background-color: $color__white;
                border-color: $color__gray--light;
            }
        }
    }

    .dgf-list__map {
        display: none;

        .leaflet-popup{
            z-index: 99999;
        }
    }

    &:not(.dgf-list--list){

        .card-text{

            a:not(:first-of-type) {
                margin-top: 8px;
            }
        }
    }

    &.dgf-list--list{

        .dgf-list__header {
            margin-bottom: 32px;

            @include mq(md) {
                margin-bottom: 48px;
            }

            .accordion-body & {
                margin-bottom: 8px;
            }
        }

        .dgf-list__body{
            > .dgf-row{
                > .col{
                    &:last-of-type{
                        > .card{
                            border-bottom-width: 0;
                        }
                    }
                }
            }
            hr{
                margin: 0;
            }
        }

        .row{
            gap: 0;
        }

        .col{
            flex: 0 0 auto;
            width: 100%;
        }

        .card{
            border-width: 0 0 1px 0;
            border-radius: 0;

            background-color: transparent;

            &-header{
                background-color: transparent;
                padding-left: 0;
                padding-top: var(--bs-card-spacer-y);

                + .card-body {
                    padding-top: 0;
                }
            }

            &-body{
                flex-direction: row;
                gap: var(--bs-card-spacer-y);

                max-width: 1100px;
                padding: var(--bs-card-spacer-y) 0;

                @include mq(md, max) {
                    display: block;
                }

                .dgf-card-icon--arrow,
                .dgf-card-icon--download{
                    display: none;
                }
            }

            &-title{
                flex: 2;
                margin-bottom: 0;

                a{
                    color: $color__link!important;
                    text-decoration-color: currentColor;

                    &:hover,
                    &:focus {
                        color: $color__primary--hover!important;
                        text-decoration-color: transparent!important;
                    }

                    &:after,
                    &:before{
                        display: none;
                    }
                }
            }

            &-text {
                flex: 3;
                flex-direction: row;
                margin-top: 4px!important;
                gap: var(--bs-card-spacer-y);

                @include mq(md, max) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: calc(var(--bs-card-spacer-y) * .5);
                }

                &__info {
                    flex: 3;

                    @include mq(lg, max) {
                        flex: 1;
                    }
                }

                &__contact {
                    flex: 2;
                    padding: 0;
                    margin: 0;

                    @include mq(lg, max) {
                        flex: 1;
                    }

                    @include mq(md, max) {
                        display: none;
                    }
                }
            }
        }

        .dgf-card-image,
        .card-img-top{
            display: none;
        }
    }


    &.dgf-list--map{

        .dgf-list__body {
            display: none;
        }

        .dgf-list__map {
            display: block;
        }

    }

    &-combined {
        .dgf-list-combined__header {
            margin-bottom: 32px;
        }
    }

    .map-marker--svg{
        svg{
            path{
                fill: var(--color-primary);
            }
        }
    }
}
